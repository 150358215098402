@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #1e90ff;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
  min-width: 160px;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.headerLogo {
  width: 300px;
}
.content {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
}
.home h1 {
   color: #1e90ff;
   font-size:x-large;
   font-weight: bold;
}
.home h2 {
  color: #f1356d;
  font-size: large;
  font-weight: bold;
}
.home h3 {
  font-size: x-large;
}
.h1About {
  color: #1e90ff;
  font-size:x-large;
  font-weight: bold;
}
.h2About {
  color: #f1356d;
  font-size: large;
  font-weight: bold;
}
.socialMediaIcons {
  color: white;
  font-size: 1rem;
  display: inline;
}
.blueLine {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: #ffffff;
  border-width: 3px;
  width: 600px;
}
.blueLineFaintShort {

  border-color: lightgray; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: #ffffff;
  border-width: 1px;
  width: 300px;
  margin-left: 20px;
}
.blueLinePGA {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: #ffffff;
  border-width: 3px;
  width: 600px;
}
.grayline {
  border-bottom: 1px solid #888;
  float: right;
  right: 0px;
  width: 155px;
}

/* RADIO BUTTON LIST */
.radio-label {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-right: 70px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-left: 25px;
}

.radio-labelImage {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked + span {
  border: 2px solid #f1356d;
}

.radio-input:checked + span:after {
  opacity: 10;
}

.radioImageDiv {
  display: flex;
  width: 200px;
  height: 100px;
}

.custom-radioImage {
  left: -6px;
  top: 4px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

/* for inner filled circle */
.custom-radioImage::after {
  content: '';
  width: 9px;
  height: 9px;
  background: #f1356d;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.custom-radio {
  left: -6px;
  top: 4px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;

}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 9px;
  height: 9px;
  background: #f1356d;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

/* PLAYERS*/
/* NFL */
.player-view {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 8rem 4rem 12rem 1rem 2rem 4rem 6rem;
  grid-auto-flow: row;
  height: 99px;
}
/* NFL */
.player-view-col {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 8rem 4rem 12rem 1rem 2rem 4rem 6rem;
  grid-auto-flow: row;
}
.player-view-nfllogo {
  margin-top: 1px;
  width: 40px; /* 100 */
  height: 30px; /* 75 */
}
.player-view-funnel {
  margin-top: 6px;
  width: 18px; /* 100 */
  height: 18px; /* 100 */
}
.player-view-funnel-single {
  margin-bottom: 4px;
  width: 18px; /* 100 */
  height: 18px; /* 100 */
}
.player-view-rwLogo {
  margin-top: 1px;
  margin-left: 2px;
  width: 24px; /* 36 */
  height: 16px; /* 24 */
}
.player-view-rwLogo-NFL-FullLineup {
  margin-top: 1px;
  margin-left: 2px;
  width: 24px; /* 36 */
  height: 16px; /* 24 */
}
.player-view-MLB-col-Responsive {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 8rem 5rem 14rem 3rem 4rem;
  grid-auto-flow:row;
}
.player-view-MLB-Responsive {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 8rem 5rem 14rem 3rem 4rem;
  grid-auto-flow:row;
  height: 99px;
}
.player-view-PGA {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 8rem 4rem 13rem 4rem 8rem;
  grid-auto-flow: row;
  height: 99px;
}
.player-view-PGA-col {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 8rem 4rem 13rem 4rem 8rem;
  grid-auto-flow: row;
}
.player-item {
  padding: 5px 8px;
  margin: 5px 0;

  width:auto;
  display: inline;
  height: 99px;
}
.player-itemLock { /* Used above NFL player name */
  padding: 5px 8px;
  margin: 5px 0;
  border-top: 3px solid #f8e537;
  border-bottom: 3px solid #f8e537;
  border-right: 3px solid #f8e537;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-PGA-col {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.player-item-pga-smallfont {
  padding: 5px 8px;
  margin: auto;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-pga-normalfont {
  padding: 5px 8px;
  margin: 25px 0;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-NFL-col {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.player-item-responsive {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-PGA-col-responsive {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.player-item-NFL-col-responsive {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.player-item-margintop {
  padding: 5px 8px;
  margin: 32px 0;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-NFL-logo {
  margin: 31px 5px;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-funnel-logo {
  margin: 31px 5px;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-margintop-center {
  padding: 5px 8px;
  margin: 32px 0;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
  text-align: center;
}
.player-item-marginauto {
  padding: 5px 8px;
  margin: auto;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-margintop_DK
{
  padding: 17px 8px;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-margintop_FD
{
  margin: 37px 10px;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
  text-align: left;
}
.player-item-MLB {
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-MLB-playername {
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-MLB-col {
  width:auto;
  display: inline;
}
.player-item-MLB-col-Responsive {
  width:auto;
  display: inline;
}
.player-item-MLB-lineupOrderImage {
    
    width: 24px; /* 240 */
    height: 24px; /* 240 */
}
.responsive-MLB-playerInfo {
  visibility: hidden;
}
.responsive-NFL-playerInfo {
  visibility: hidden;
}
.responsive-PGA-playerInfo {
  visibility: hidden;
}
.player-item-small {
  margin-top: 20px;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-small-Responsive {
  margin-top: 20px;
  width:auto;
  display: inline;
  font-size: small;
  height: 99px;
}
.player-item-image {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-imageLock {
  padding: 5px 8px;
  margin: 5px 0;
  border-top: 3px solid #f8e537;
  border-bottom: 3px solid #f8e537;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-image-MLB-image {

  text-align: right;
  margin-top: 10px;
  width: 60px; /* 140 */
  height: 80px; /* 187 */
}
.player-item-image-lineup {
  position: relative;
  bottom: 95px;
  margin: 10px 40px;
}
.player-item-image-lineup-MLB-Single {
  position: relative;
  bottom: 95px;
  margin: 25px 40px;
}

/* dirty CSS for 'lock' feature */
/* This is used for NFL around the stars ranking */
.player-itemtrue {
  padding: 5px 8px;
  margin: 5px 0;
  border-top: 3px solid #f8e537;
  border-bottom: 3px solid #f8e537;
  border-left: 3px solid #f8e537;
  width:auto;
  display: inline;
  height: 99px;
}
/* dirty CSS for when 'lock' feature is *not* checked */
.player-itemfalse {
  padding: 5px 8px;
  margin: 5px 0;

  width:auto;
  display: inline;
  height: 99px;
}
.player-item-MLB-true {
  padding: 5px 8px;
  margin: 5px 0;
  border-top: 3px solid #f8e537;
  border-bottom: 3px solid #f8e537;
  width:auto;
  display: inline;
  height: 99px;
}
/* dirty CSS for when 'lock' feature is *not* checked */
.player-item-MLB-false {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  height: 99px;
}
.player-item-gamedate {
  padding: 5px 8px;
  font-size: smaller;
  margin-top: 13px;
  height: 99px;
}
.player-list {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-width: 3px;
  width: 600px;
}
.ButtonPositionsNFL {
  padding: 5px 20px;
}
/* Football */
.Rating_QB {
  width:auto;
  display: inline;
  visibility: hidden;
  font-size: small;
}
.Rating_RB {
  width:auto;
  display: inline;
  font-size: small;
}
.Rating_WR {
  width:auto;
  display: inline;
  font-size: small;
}
.Rating_TE {
  width:auto;
  display: inline;
  font-size: small;
}
.Rating_DST {
  width:auto;
  display: inline;
  visibility: hidden;
  font-size: small;
}
.Lock {
  width:auto;
  display: flex;
  font-size: small;
}
.LockImageResponsive {
width: 20px; /* 20 */
height: 27px; /* 27 */
}
.Lock_G {
  width:auto;
  display: inline;
  font-size: small;
}
.Lock_QB {
  width:auto;
  display: inline;
  visibility: hidden;
  font-size: small;
}
.Lock_RB {
  width:auto;
  display: inline;
  font-size: small;
}
.Lock_WR {
  width:auto;
  display: inline;
  font-size: small;
}
.Lock_TE {
  width:auto;
  display: inline;
  font-size: small;
}
.Lock_DST {
  width:auto;
  display: inline;
  visibility: hidden;
  font-size: small;
}
/* Stack visibility */
.Stack_Hidden {
  width:auto;
  display: inline;
  visibility: hidden;
  font-size: small;
}
.Stack_Visible {
  width:auto;
  display: inline;
  font-size: small;
}

.OppRankBrutal {
  color:#8e0000;
  font-size:small;
  font-weight: bold;
  display: inline;
}
.OppRankHard {
  color: #FF1e1e;;
  font-size:small;
  font-weight: bold;
  display: inline;
}
.OppRankSlightlyHard {
  color: #d4b401;;
  font-size:small;
  font-weight: bold;
  display: inline;
}
.OppRankAverage {
    font-size:small;
    font-weight: bold;
    display: inline;
  }
  .OppRankSlightlyEasy {
    color: #9dd600; /* c4d600*/
    font-size:small;
    font-weight: bold;
    display: inline;
  }
.OppRankEasy {
  color: #23ac05;
  font-size:small;
  font-weight: bold;
  display: inline;
}
.OppRankCupcake {
  color: #31f207;
  font-size:small;
  font-weight: bold;
  display: inline;
}
/* BASEBALL */
.OppRankMLB {
  font-size:small;
  display:flex; /* inline */

  margin-left: 5px;
}

.Matchup {
  color: #1e90ff;
  font-size: small;
  font-weight: bold;
  display: inline;
}
.Matchup-MLB-Responsive {
  visibility: hidden;
}
.PlayerStat {
  color: #23ac05;
  font-size:small;
  font-weight: bold;
  display: inline;
}
.Matchupbox {
  display: inline;
  border-bottom: #1e90ff;
  border-top: #1e90ff;
  border-right: white;
  border-left: white;
  border-width: 1px;
  border-style:solid;
  padding-bottom: 2px;
}
.Matchupbox-MLB {
  display: inline;
  border-bottom: white;
  border-top: white;
  border-right: white;
  border-left: white;
  border-width: 1px;
  border-style:solid;
  padding-bottom: 2px;
  margin-bottom: 1px;
}
.Matchupbox-MLB-underline {
  display: inline;
  border-bottom: white;
  border-top: #1e90ff;
  border-right: white;
  border-left: white;
  border-width: 1px;
  border-style:solid;
  padding-bottom: 2px;
}
.InjuryStatus {
  color: red;
  display: inline;
  font-weight: bold;
}
.InjuryStatusSmallResponsive {
  color: red;
  display: inline;
  font-weight: bold;
}
/* LINEUPS*/
.lineup-view-NFL {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 4rem 10rem 3rem 4rem 7rem 6rem;
  grid-auto-flow: row;
}
.lineup-view-MLB {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 5rem 12rem 3rem 4rem 6rem 5rem;
  grid-auto-flow: row;
}
.lineup-view-PGA {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 4rem 10rem 8rem 6rem 4rem;
  grid-auto-flow: row;
}
.lineup-item {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineup-item-MLB-playerImage {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineup-item-PGA {
  padding: 5px 8px;
  margin: auto;
  width:auto;
  display: inline;
}
.lineup-item-alignright {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineup-item-center {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  text-align: center;
}
.lineup-item-mlb {
  padding: 25px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineup-item-mlb-center {
  padding: 25px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  text-align: center;
}
.lineup-item-mlb-xsmall {
  padding: 25px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  text-align: center;
}
.divMLBPosFilter {
  width: 400px;
  display: inline;
  text-align: center;
}
.lineup-item-NFL-margin {
  padding: 24px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineup-item-NFL-margin-xsmall {
  padding: 24px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  text-align: center;
}
.lineup-item-NFL-margin-paddingright {
  padding: 30px 21px;
  margin: 1px 0;
  width:auto;
  display: inline;
  font-size: smaller;
text-align: center;
  }
.lineup-item-NFL-name {
  padding: 25px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineup-item-small {
  padding: 12px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  font-size: 13px;
}
.lineup-itemRight {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  text-align: right;
}
.lineup-itemLock {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
}
.lineupLockImage-{
  visibility: hidden;
}
.lineupLockImagePGA-{
  visibility: hidden;
}
.lineupLockImagebulk-{
  visibility: hidden;
}
.lineupLockImage-Lock{
visibility: visible;
width: 60px;
text-align: center;
}
.lineupLockImagePGA-Lock{
  visibility: visible;
  width: 60px;
  text-align: center;
  margin:auto;
  }
.lineupLockImageLeftAlign-Lock {
  visibility: visible;
width: 60px;
text-align: center;
margin-top:25px;
}
.lineupLockImageLeftAlign-{
  visibility: hidden;
}
.lineupLockImagebulk-Lock{
  visibility: visible;
  margin-top: 22px;
  margin-left: 15px;
  }
.lineup-item-singleLineup-{
  visibility: hidden;
}
.lineup-item-singleLineup-NFL-{
  visibility: hidden;
}
.lineup-item-singleLineup-Lock{
  visibility: visible;
  width: 60px;
  text-align: left;
  }
  .lineup-item-singleLineup-NFL-Lock{
    visibility: visible;
    width: 60px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    }
.lineup-view-singleLineup-PGA {

  display: grid;
  grid-template-columns: 5rem 2rem 4rem 13rem 9rem 4rem;
  grid-auto-flow: row;
}
.lineup-view-singleLineup-NFL {

  display: grid;
  grid-template-columns: 4rem 2rem 4rem 12rem 1rem 3rem 5rem 3rem 3rem;
  grid-auto-flow: row;
}
.lineup-view-singleLineup-NFL-col {

  display: grid;
  grid-template-columns: 4rem 2rem 4rem 12rem 1rem 3rem 5rem 3rem 3rem;
  grid-auto-flow: row;
}
.lineup-view-singleLineup-MLB {

  display: grid;
  grid-template-columns: 5rem 2rem 4rem 11rem 3rem 6rem 3rem 3rem;
  grid-auto-flow: row;
}
.lineup-item-player-image {
  height: 75px;
  width: 60px;
  display: block;
}
.lineup-item-singleLineup {

  width:auto;
  display: inline;
  padding: 1px 1px;
}
.lineup-item-singleLineup-center {

  width:auto;
  display: inline;
  padding: 1px 1px;
  text-align: center;
}
.lineup-item-singleLineup-margin {

  width:auto;
  display: inline;
  margin-top:auto;
  margin-bottom:auto;
}
.lineup-item-singleLineup-margin-small {

  width:auto;
  display: inline;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.lineup-item-singleLineup-margin-salary {
  color: dodgerblue; 
  font-size:smaller;
  font-weight: bold;
  width:auto;
  display: inline;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.lineup-item-singleLineup-responsive {

  width:auto;
  display: inline;
  padding: 1px 1px;
}
.responsiveHide {
  display: inline;
}
.lineup-item-singleLineup-mlb {

  width:auto;
  display: inline;
  padding: 1px 1px;
}
.lineup-item-singleLineup-mlb-padding {
  width:auto;
  display: inline;
  padding: 1px 1px;
  margin-top: 18px;
}
.lineup-item-singleLineup-mlblogo {

  text-align: right;
  margin-top: 15px;
  width: 40px; /* 100 */
  height: 30px; /* 75 */
}
.lineup-item-bulk-mlblogo {
vertical-align: middle;
  width: 40px; /* 100 */
  height: 30px; /* 75 */
}
.lineup-item-bulk-playerimage {
  width: 60px; /* 140 */
  height: 80px; /* 187 */
}
.lineup-item-singleLineup-nfllogo {

  text-align: right;
  width: 40px; /* 100 */
  height: 30px; /* 75 */
}
.lineup-item-bulkLineup-nfllogo {

  text-align: right;
  margin-top: 1px;
  width: 40px; /* 100 */
  height: 30px; /* 75 */
}
.lineup-item-bulkLineup-stars {
  margin-top: 1px;
  width: 100px; /* 125 */
  height: 20px; /* 25 */
}
.ScreenShotSingleLineup {
  background-color: white;
}
.ScreenShotIcon {
  margin-left: 15px;
  cursor: pointer;
}
.lineup-item-singleLineup-MLBSalary {

  width:auto;
  display: inline;
  padding: 1px 1px;
  margin-top: 18px;
}
.lineup-item-singleLineup-Border-TE {

  width:auto;
  display:inline;
  text-align: center;
  margin-top:38%;
  border-width:8px;
  border-bottom: red;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-QB {

  width:auto;
  display:inline;
  text-align: center;
  margin-top:38%;
  border-width:8px;
  border-bottom:   purple;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-RB {

  width:auto;
  display:inline;
  text-align: center;
  margin-top:38%;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-DST {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  margin-top:38%;
  border-bottom: #FFFFFF;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-WR {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  margin-top:38%;
  border-bottom: lightseagreen;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-WR2 {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  margin-top:38%;
  border-bottom: lightseagreen;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-Border-TE {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height: 60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: red;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-Border-QB {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height: 60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: purple;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-Border-DST {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height: 60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: #1e90ff;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-Border-WR {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height: 60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: lightseagreen;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-Border-RB {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height: 60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-Golfer {

  width:auto;
  display:inline;
  text-align: left;
  border-width:8px;
  margin-top: 22px;
  border-bottom: #FFFFFF;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-Golfer-center {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  margin-top: 22px;
  border-bottom: #FFFFFF;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}

.lineup-item-singleLineup-Border-P {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom:   purple;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-OF {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom:   green;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-OF3 {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom:  #FFFFFF;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-Border-AllBases {

  width:auto;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: burlywood;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #fefefe;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-P {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: purple;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-C {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-1 {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-2 {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-3 {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-S {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-bulkLineup-MLB-Border-O {
  padding: 22px 8px;
  margin: 5px 0;
  width:auto;
  height:60px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom:green;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
}
.lineup-item-singleLineup-gamedate {
  width:auto;
  display: inline;
  font-size: small;
}
.lineup-item-singleLineup-gamedate-margin {
  width:auto;
  display: inline;
  font-size: x-small;
margin-top: auto;
margin-bottom: auto;
  text-align: center;
}
.lineup-item-singleLineup-gamedate-PGA {
  width:auto;
  display: inline;
  font-size: small;
  margin-top: 30px;
}

.lineup-item-singleLineup-StackImage-true{
  visibility: visible;
  text-align: right;
  width: 38px; /* 48 */
  height: 35px; /* 43 */
}
.lineup-item-singleLineup-StackImage-false{
  visibility: hidden;
}

.lineup-item-singleLineup-StackImage-P{
  visibility: visible;
  text-align: right;
  margin-top: 15px;
  width: 33px; /* 48 */
  height: 30px; /* 43 */
}
.lineup-item-singleLineup-StackImage-S{
  visibility: visible;
  text-align: right;
  margin-top: 15px;
  width: 33px; /* 48 */
  height: 30px; /* 43 */
}
.lineup-item-singleLineup-StackImage-{
  visibility: hidden;
}
.LockImageSize {
  text-align: right;
  width: 20px; /* 20 */
  height: 27px; /* 27 */
}
.lineup-list {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-top: #ffffff;
  border-width: 3px;
  width: 600px;
}

.lineup-list-singleLineup {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-top: #ffffff;
  border-width: 3px;
  width: 600px;
}

.lineup-list-singleLineup-PGA {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-top: #ffffff;
  border-width: 3px;
  width: 600px;
}

.button {
  background-color: #f1356d;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 16px;
  font-family: Quicksand;
  border-radius: 8px;
  cursor:pointer;
  position: absolute;
}
.buttonQuickRank {
  background-color: white;
  border: none;
  color: #1e90ff;
  text-align: center;
  text-decoration:underline;
  display: inline;
  font-size: 13px;
  font-family: Quicksand;
  font-weight: bold;
  cursor:pointer;
}
.buttonQuickRankMLB {
  background-color: white;
  border: none;
  color: #1e90ff;
  text-align: center;
  text-decoration:underline;
  display: inline;
  font-size: 13px;
  font-family: Quicksand;
  font-weight: bold;
  cursor:pointer;
}
.buttonDownload {
  
  border: none;
  color: blue;
  padding: 10px 1px;
  text-align: center;
  text-decoration:underline;
  display: inline;
  font-size: 16px;
  font-family: Quicksand;
  border-radius: 8px;
  cursor:pointer;
  position: absolute;
  background-color: white;
}

.buttonRemove {
  background-image: url(../public/RemoveCustomIcon.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  /* put the height and width of your image here */
  height: 16px;
  width: 16px;
  border: none;
  cursor:pointer;
  padding-top: 10px;
}

.buttonAdd {
  background-image: url(../public/AddCustomIcon.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  /* put the height and width of your image here */
  height: 16px;
  width: 16px;
  border: none;
  cursor:pointer;
  padding-top: 10px;
}

.div-inline {
  max-width: 600px;
  display: inline;
}

.div-flex {
  max-width: 600px;
  display:flex;
}

.div-flexLeft {
  max-width: 600px;
  display:flex;
}

.centerRedMessage {
  font-weight: bold;
  font-size: 30px;
  text-align-last: center;
  display: block;
  color:#f1356d;
}

.div-flexRight {
  max-width: 600px;
  display:flex;
  justify-content: flex-end;
}
.div-flexCenter {
  max-width: 600px;
text-align: center;
}
.div-right-align {
  display:inline;
  justify-content:end;
  align-items:flex-end;
align-content: flex-end;

}
.div-quickRank {
  display:inline-block;
  padding-left: 2px;

}
.div-quickRankMLB {
  display:inline-block;
  padding-left: 2px;

}
.div-flexRightGenerate {
  max-width: 600px;
  display:flex;
  justify-content: flex-end;
}
.div-flexRightGenerate-MLB {
  max-width: 600px;
  display:flex;
  justify-content: flex-end;
  padding-bottom: 2px;
}
.labelItalic {
  font-style: italic;
  margin-left: 5px;
  margin-top: 3px;
}
.labelItalicMLB {
  font-style: italic;
  margin-left: 5px;
  margin-top: 3px;
}
.checkbox {
  display: inline-block;
  position: relative;
/* width: 60px;*/  
  vertical-align: middle;
  background: white left top no-repeat;
  cursor:pointer;
  margin-left: 15px;
}

.toolTipImage {
  height:20px;
  width:20px;
  margin-left: 5px;
}

.passwordLabel {
  margin-right: 5px;
  font-weight: bold;
}

.betaCode {
  width:150px;
  margin-top: 10px;
}

/* The container must be positioned relative: */
.custom-select {
  width: 475px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  font-family: Quicksand;
  display: inline-block;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

/* The container must be positioned relative: */
.custom-select-teams {
  width: 400px;
  margin-left: 0px; /*207 for middle */
  height: 44px;
  margin-bottom: 10px;
  font-family: Quicksand;
  display: inline-block;
  font-size: 12px;
}

.select-selected {
  background-color: #1e90ff;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: #1e90ff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 5vh;
  margin: auto;
}
/*
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid DodgerBlue;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
*/
.example-thumb-slider{
  cursor: grab;
  position: absolute;
  z-index: 100;
  background: #FFFFFF;
  border: 5px solid DodgerBlue;
  border-radius: 100%;
  border-style:solid;
  height: 25px;
  width: 25px;
  padding-top: 15px;
  margin-top: 10px;
  text-align: center;
  outline: 0;
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 58px;
}

.divIndent {
  padding-left: 30px;
}
.divSmallerFont {
  font-size: smaller;
  display: inline;
}

.divIndentLots {
  padding-left: 60px;

}
.divIndentLotsItalics {
  font-style: italic;
  padding-left: 60px;
  font-size: small;
}
.divInlineAlignRight {
  display: inline;
margin-left: 8px; /*165 px for right */
}
.divInlineMarginLeft {
  display: inline;
   margin-left: 150px;
}
.divInlineResponsiveHide {
  display: inline;
margin-left: 8px; /*165 px for right */
}
.inputsmall {
  width: 10px;
}
.divInlineAndHeight {
  display: inline;
}
.divInlineAlignLeft {
  display: inline;
  text-align: left;
}
.divInlineAndHeightInlineBlock {
  display: inline-block;
}

.divIndentAndColor {
  padding-left: 30px;
  color: DodgerBlue;
  font-weight: bold;
}

.divIndentAndCenterAndColor {
  color: DodgerBlue;
  font-weight: bold;
  padding-left: 10px;
  display: flex;
  justify-content: center;
}

.divPink {
 color: #f1356d; 
 display: inline-block;
 font-weight: bold;
}
.divBlue {
  color: dodgerblue; 
  display: inline-block;
  font-weight: bold;
 }
 .divBold { 
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
 }
 .divBoldResponsive { 
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
 }
 .divBoldResponsiveSmall { 
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
 }
 .divBoldResponsive-marginTop {
  padding: 27px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  font-weight: bold;
  font-size: 15px;
 }
 .divBoldSmall { 
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
 }
 .divBoldVerySmall { 
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
 }
 .divBoldExtraSmall { 
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
 }
 .divBoldSmallItalics { 
  display:inline-block;
  font-style: italic;
  font-size: 14px;
  padding-left: 5px;
 }
 .responsive-MLB-divBoldSmallItalics {
  display:inline-block;
  font-style: italic;
  font-size: 14px;
  padding-left: 5px;
  width: 18px;
 }
 .divBoldSmallNoWrap { 
  display: inline;
  font-weight: bold;
  font-size: x-small;
 }
 .divSmallNoWrap { 
  display: inline;
  font-size: x-small;
 }
.divFinePrint {
  font-size: 14px;
  font-style: italic;
 }
.divXSmallFinePrint {
  display: inline;
  font-size: 13px;
  font-style: italic;
}

/**
* ----------------------------------------------
* Accordian style
* ----------------------------------------------
**/
.accordion {
  border: 0px solid #cce4fc;
  border-radius: 0px;
  max-width: 400px;
} 

.accordion__item + .accordion__item {
  border-top: 0px solid rgba(0, 0, 0, 0.1);

}
.accordion__item {
  width: 100%;
}

.accordion__heading {
width: 100%;
}
.accordion__button {
  background-color: #FFFFFF;
  color: #000000;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  max-width: 400px;
  /*width: 500px;*/
  text-align: left;
  border: 0px solid #cce4fc;
}

.accordion__button:hover {
  background-color: #FFFFFF;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
  border-top: 1px solid #cce4fc;

  border-bottom: 1px solid #cce4fc;
}

/* -------------------------------------------------- */
/* ---------------- Animation part of accordian------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.error {
  color: red;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 18px;
  font-family: Quicksand;
  font-weight: bold;
}

.success {
  color: dodgerblue;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 18px;
  font-family: Quicksand;
  font-weight: bold;
}



.warning {
  color:#ff9966;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 18px;
  font-family: Quicksand;
  font-weight: bold;
}

.loading {
  color:DodgerBlue;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 18px;
  font-family: Quicksand;
  font-weight: bold;
}
.loadingNFL {
  color:DodgerBlue;
  font-size: 18px;
  font-family: Quicksand;
  font-weight: bold;
  display:flex;
  justify-content: flex-end;
  padding: 0px 0px;
  margin: 0px 0px;
}










/* -------------------------------------------------- */
/* ---------------- MOBILE RESPONSIVENESS------ */
/* -------------------------------------------------- */










@media (max-width: 600px){

  .navbar {
    padding: 10px;
    display: inline-block;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid #1e90ff;
  }
.navbar h1 {
  color: #f1356d;
}
.navbar .links {

  min-width: 80px;
 margin-left: 0;
 padding-bottom: 10px;
 padding-top: 5px;
}
.navbar a {
  margin-left: 5px;
  text-decoration: none;
  padding: 6px;

}
.blueLine {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: #ffffff;
  border-width: 3px;
  width: 377px;
}
.blueLineFaintShort {

  border-color: lightgray; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: #ffffff;
  border-width: 1px;
  width: 300px;

}
.blueLinePGA {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: #ffffff;
  border-width: 3px;
  width: 377px;
}
.headerLogo {
  width: 250px;
}
.accordion {
  border: 0px solid #cce4fc;
  border-radius: 0px;
  max-width: 375px;
} 
.custom-select {
  width: 350px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  font-family: Quicksand;
  display: inline-block;
}
.custom-select-teams {
  width: 325px;
  height: 50px;
  margin-left: 0px;
  margin-right: 10px;
  padding-bottom: 5px;
  position: relative;
  font-family: Quicksand;
  display: inline-block;
}
/* NFL */
.player-view {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 5rem 4rem 10rem 0rem 0rem 0rem 0rem;
  grid-auto-flow: row;
  height: 135px;
}
/* NFL */
.player-view-col {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 5rem 4rem 10rem 0rem 0rem 0rem 0rem;
  grid-auto-flow: row;
}
.player-view-nfllogo {

  text-align: right;
    margin-top: 0px;
    width: 33px; /* 100 */
    height: 25px; /* 75 */
}
.player-view-funnel {
  text-align: right;
    margin-top: 0px;
    width: 20px; /* 100 */
    height: 20px; /* 100 */
}
.player-view-funnel-single {
  text-align: right;
    margin-top: 0px;
    width: 12px; /* 100 */
    height: 12px; /* 100 */
}
.player-view-rwLogo {
  margin-top: 1px;
  margin-left: 3px;
  width: 15px; /* 36 */
  height: 10px; /* 24 */
}
.player-view-rwLogo-NFL-FullLineup {
  margin-top: 1px;
  margin-left: 3px;
  width: 18px; /* 36 */
  height: 12px; /* 24 */
}
.player-view-MLB-Responsive {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 5rem 5rem 11rem 0rem 0rem;
  grid-auto-flow: row;
  height: 125px;
}
.player-view-MLB-col-Responsive {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 5rem 5rem 11rem 0rem 0rem;
  grid-auto-flow: row;
}
  .player-view-PGA {
    padding: 1px 1px;
    margin: 1px 0;
    border-bottom: 1px solid #fafafa;
    grid-template-columns: 5rem 4rem 8rem 0rem 0rem;
    display: grid;
    grid-auto-flow: row;
    height: 150px;
  }
  .player-view-PGA-col {
    padding: 1px 1px;
    margin: 1px 0;
    border-bottom: 1px solid #fafafa;
    grid-template-columns: 5rem 4rem 8rem 0rem 0rem;
    display: grid;
    grid-auto-flow: row;
  }
  .responsive-MLB-divBoldSmallItalics {
    visibility: hidden;
   }
   .divBoldVerySmall { 
    display: inline-block;
    font-weight: bold;
    font-size: 9px;
   }
   .divBoldExtraSmall { 
    display: inline-block;
    font-weight: bold;
    font-size: 10px;
   }
   .divBoldResponsive { 
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
   }
   .divBoldResponsiveSmall { 
    display: inline-block;
    font-weight: bold;
    font-size: 10px;
   }
   .divBoldResponsive-marginTop { 
    padding: 22px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-weight: bold;
    font-size: 12px;
   }
  .player-list {

    border-color: #1e90ff; 
    border-style: solid;
    border-right: #ffffff;
    border-left: #ffffff;
    border-width: 3px;
    width: 377px; /*405 */
  }
  .ButtonPositionsNFL {
    padding: 5px 15px;
  }
  .lineup-list {

    border-color: #1e90ff; 
    border-style: solid;
    border-right: #ffffff;
    border-left: #ffffff;
    border-top: #ffffff;
    border-width: 3px;
    width: 377px;
  }
  .InjuryStatusSmallResponsive {
    color: red;
    display: inline;
    font-weight: bold;
    font-size: 10px;
  }
  .lineup-view-NFL {
    padding: 1px 1px;
    margin: 1px 0;
    border-bottom: 1px solid #fafafa;
    display: grid;
    grid-template-columns: 4rem 7rem 2rem 2rem 4rem 3rem;
    grid-auto-flow: row;
}
.lineup-item-bulkLineup-Border-TE {
  padding: 15px 2px;
  margin: 5px 0;
  width:auto;
  height:50px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: red;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
  font-size: smaller;
}
.lineup-item-bulkLineup-Border-QB {
  padding: 15px 2px;
  margin: 5px 0;
  width:auto;
  height:50px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: purple;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
  font-size: smaller;
}
.lineup-item-bulkLineup-Border-DST {
  padding: 15px 2px;
  margin: 5px 0;
  width:auto;
  height:50px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: #1e90ff;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
  font-size: smaller;
}
.lineup-item-bulkLineup-Border-WR {
  padding: 15px 2px;
  margin: 5px 0;
  width:auto;
  height:50px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: lightseagreen;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
  font-size: smaller;
}
.lineup-item-bulkLineup-Border-RB {
  padding: 15px 2px;
  margin: 5px 0;
  width:auto;
  height:50px;
  display:inline;
  text-align: center;
  border-width:8px;
  border-bottom: orange;
  border-right: #FFFFFF;
  border-top: #FFFFFF;
  border-left: #FFFFFF;
  border-style:solid;
  font-size: smaller;
}

.lineup-view-MLB {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 4rem 5rem 2rem 3rem 3rem 4rem;
  grid-auto-flow: row;
}
.lineup-view-PGA {
  padding: 1px 1px;
  margin: 1px 0;
  border-bottom: 1px solid #fafafa;
  display: grid;
  grid-template-columns: 4rem 7rem 4rem 4rem 3rem;
  grid-auto-flow: row;
}
.lineupLockImage-Lock{
  visibility: visible;
  margin-top: 20px;
  }
  .lineupLockImagePGA-Lock{
    visibility: visible;
    margin-top: 20px;
    }
  .lineupLockImageLeftAlign-Lock{
    visibility: visible;
    margin-top: 20px;
    text-align: left;
    }
  .lineupLockImagebulk-Lock{
    visibility: visible;
    margin-top: 14px;

    }
  .LockImageResponsive {
    width: 14px; /* 20 */
    height: 21px; /* 27 */
    }
.lineup-item {
    padding: 1px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
  }
  .lineup-item-MLB-playerImage
  {
    padding: 1px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
  }
  .lineup-item-PGA {
    padding: 1px 1px;
    margin: auto;
    width:auto;
    display: inline;
    font-size: smaller;
  }
  .lineup-item-alignright {
    padding: 1px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
    text-align: right;
  }
  .lineup-item-center {
    padding: 1px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
  }
  .lineup-item-mlb {
    padding: 22px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
  }
    .lineup-item-mlb-center {
    padding: 20px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
    text-align: center;
  }
  .lineup-item-mlb-xsmall {
    padding: 22px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: x-small;
  }
  .divMLBPosFilter {
    width: 250px;
    display: inline;
    text-align: left;
    font-size:x-small;
  }
 .lineup-item-NFL-margin {
  padding: 21px 1px;
  margin: 1px 0;
  width:auto;
  display: inline;
  font-size: smaller;

  }
  .lineup-item-NFL-margin-xsmall {
    padding: 21px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: x-small;
    text-align: center;
    }
  .lineup-item-NFL-margin-paddingright {
    padding: 21px 21px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
  
    }
  .lineup-item-NFL-name {
    padding: 21px 1px;
    margin: 1px 0;
    width:auto;
    display: inline;
    font-size: smaller;
  }
.lineup-item-singleLineup {

    width:auto;
    display: inline;
    font-size: smaller;
    padding: 1px 1px;

  }
  .lineup-item-singleLineup-center {

    width:auto;
    display: inline;
    font-size: smaller;
    padding: 1px 1px;
    text-align: center;
  }
  .lineup-item-player-image {
    height: 65px;
    width: 52px;
    display: block;
  }
  .lineup-item-singleLineup-margin {

    width:auto;
    display: inline;
    font-size: small;
    padding: 1px 1px;
    margin-top:auto;
    margin-bottom: auto;
    text-align: left;

  }
  .lineup-item-singleLineup-margin-small {

    width:auto;
    display: inline;
    font-size: small;
    padding: 1px 1px;
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;

  }

  .lineup-item-singleLineup-margin-salary {
    color: #1e90ff;
    width:auto;
    display: inline;
    font-size: small;
    padding: 1px 1px;
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;

  }


  .lineup-item-singleLineup-responsive {

    visibility: hidden;
  }
  .responsiveHide {
    visibility: hidden;
  }
  .responsiveNoDisplay {
    display: none;
  }
  .lineup-item-singleLineup-mlb {

    width:auto;
    display: inline;
    font-size: smaller;
    padding: 1px 1px;
  }
  .lineup-item-singleLineup-mlb-padding {

    width:auto;
    display: inline;
    font-size: smaller;
    margin-top: 10px;
  }
  .lineup-item-singleLineup-mlblogo {
    text-align: right;
    margin-top: 15px;
    width: 36px; /* 100 */
    height: 27px; /* 75 */
  }
  .lineup-item-bulk-mlblogo {
    width: 36px; /* 100 */
    height: 27px; /* 75 */
  }
  .lineup-item-bulk-playerimage {
    width: 56px; /* 140 */
    height: 75px; /* 187 */
    display: block;
  }
  .lineup-item-singleLineup-nfllogo {
    margin-bottom: 3px;
    width: 20px; /* 100 */
    height: 15px; /* 75 */
  }
  .lineup-item-bulkLineup-nfllogo {
    text-align: right;
    margin-top: 0px;
    width: 33px; /* 100 */
    height: 25px; /* 75 */
  }
  .lineup-item-bulkLineup-stars {
    margin-top: 1px;
    width: 50px; /* 125 */
    height: 10px; /* 25 */
  }
  .lineup-item-singleLineup-gamedate {
    width:auto;
    display: inline;
    font-size:xx-small;
    margin: auto;
  }
  .lineup-item-singleLineup-gamedate-margin {
    width:auto;
    display: inline;
    font-size:xx-small;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
  }
  .lineup-item-singleLineup-gamedate-PGA {
    width:auto;
    display: inline;
    font-size:x-small;
    visibility: hidden;
  }
  .lineup-item-singleLineup-MLBSalary {

    width:auto;
    display: inline;
    font-size: smaller;
    padding: 1px 1px;
  }
  .lineup-item-singleLineup-StackImage-P{
    visibility: visible;
    text-align: right;
    margin-top: 10px;
    width: 25px; /* 48 */
    height: 22px; /* 43 */
  }
  .lineup-item-singleLineup-StackImage-S{
    visibility: visible;
    text-align: right;
    margin-top: 15px;
    width: 25px; /* 48 */
    height: 22px; /* 43 */
  }
  .lineup-item-singleLineup-StackImage-true{
    visibility: visible;
    text-align: right;
    width: 25px; /* 48 */
    height: 22px; /* 43 */
  }
 .lineup-item-singleLineup-Lock{
    visibility: visible;
    width: 20px;

  }
  .lineup-item-singleLineup-NFL-Lock{
    visibility: visible;
    width: 20px;
  }
  .LockImageSize {
    text-align: right;
    width: 15px; /* 20 */
    height: 21px; /* 27 */
  }
  .lineup-item-singleLineup-Border-TE {

    width:auto;
    display:inline;
    text-align: center;
    margin-top:88%;
    border-width:8px;
    border-bottom: red;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    font-size: small;
  }
  .lineup-item-singleLineup-Border-QB {
  
    width:auto;
    display:inline;
    text-align: center;
    margin-top:88%;
    border-width:8px;
    border-bottom:   purple;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    font-size: small;
  }
  .lineup-item-singleLineup-Border-RB {
  
    width:auto;
    display:inline;
    text-align: center;
    margin-top:88%;
    border-width:8px;
    border-bottom: orange;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    font-size: small;
  }
  .lineup-item-singleLineup-Border-DST {
  
    width:auto;
    display:inline;
    text-align: center;
    border-width:8px;
    margin-top:88%;
    border-bottom: #FFFFFF;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    font-size: small;
  }
  .lineup-item-singleLineup-Border-WR {
  
    width:auto;
    display:inline;
    text-align: center;
    border-width:8px;
    margin-top:88%;
    border-bottom: lightseagreen;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    font-size: small;
  }
  .lineup-item-singleLineup-Border-WR2 {
  
    width:auto;
    display:inline;
    text-align: center;
    border-width:8px;
    margin-top:88%;
    border-bottom: lightseagreen;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    font-size: small;
  }
  .lineup-item-bulkLineup-MLB-Border-P {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom: purple;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
  }
  .lineup-item-bulkLineup-MLB-Border-C {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom: orange;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
  }
  .lineup-item-bulkLineup-MLB-Border-1 {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom: orange;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
  }
  .lineup-item-bulkLineup-MLB-Border-2 {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom: orange;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
  }
  .lineup-item-bulkLineup-MLB-Border-3 {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom: orange;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
  }
  .lineup-item-bulkLineup-MLB-Border-S {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom: orange;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
    }
   .lineup-item-bulkLineup-MLB-Border-O {
    padding: 20px 1px;
    font-size: x-small;
    margin: 1px 0;
    width:auto;
    height: 50px;
    display:inline;
    text-align: center;
    border-width:8px;
    border-bottom:green;
    border-right: #FFFFFF;
    border-top: #FFFFFF;
    border-left: #FFFFFF;
    border-style:solid;
 }
.lineup-list-singleLineup {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-top: #ffffff;
  border-width: 3px;
  width: 377px;
}
.lineup-list-singleLineup-PGA {

  border-color: #1e90ff; 
  border-style: solid;
  border-right: #ffffff;
  border-left: #ffffff;
  border-top: #ffffff;
  border-width: 3px;
  width: 377px;
}
.lineup-view-singleLineup-PGA {

  display: grid;
  grid-template-columns: 3rem 2rem 4rem 10rem 0rem 3rem;
  grid-auto-flow: row;
}
.lineup-view-singleLineup-NFL {

  display: grid;
  grid-template-columns: 2rem 1rem 4rem 6rem 1rem 1rem 3rem 3rem 1rem;
  grid-auto-flow: row;
  height: 70px;
}
.lineup-view-singleLineup-NFL-col {

  display: grid;
  grid-template-columns: 2rem 1rem 4rem 6rem 1rem 1rem 3rem 3rem 1rem;
  grid-auto-flow: row;

}
.lineup-view-singleLineup-MLB {

  display: grid;
  grid-template-columns: 2rem 1rem 4rem 5rem 3rem 3rem 3rem 1rem;
  grid-auto-flow: row;
}
.player-item-image {
  padding: 5px 8px;
  margin-top: 40px;
  width:auto;
  display: inline;
  height: 125px;
}
.player-item-imageLock {
  padding: 5px 8px;
  margin-top: 40px;
  border-top: none;
  border-bottom: none;
  width:auto;
  display: inline;
  height: 125px;
}
.player-item-image-MLB-image {
  text-align: right;
  margin-top: 40px;
  width: 60px; /* 140 */
  height: 80px; /* 187 */
}
.player-item-small {
  width:auto;
  display: inline;
  font-size:small;
  height: 125px;
  margin-top: 30px;
  font-size: x-small;
}
.player-itemtrue {  /* true = LOCKED player */
  padding: 5px 8px;
  margin: 5px 0;
  border-top: 3px solid #f8e537;
  border-bottom: 3px solid #f8e537;
  border-left: none;
  width:auto;
  display: inline;
  height: 119px;
}
/* dirty CSS for when 'lock' feature is *not* checked */
.player-itemfalse {
  padding: 5px 8px;
  margin: 5px 0;

  width:auto;
  display: inline;
  height: 99px;
}
.player-item-small-Responsive {
 visibility: hidden;
}
.player-item-MLB {
  width:auto;
  display: inline;
  height: 125px;
}
.player-item-MLB-playername {
  width:auto;
  display: inline;
  margin-top: 10px;
  height: 125px;
}
.player-item-MLB-col {
  width:auto;
  display: inline;
}
.player-item-MLB-col-Responsive {
visibility: hidden;
}
.responsive-MLB-playerInfo {
  visibility: visible;
  font-size: smaller;
  display: inline;

}
.responsive-NFL-playerInfo {
  visibility: visible;
  font-size: x-small;
  display: inline;
}
.responsive-PGA-playerInfo {
  visibility: visible;
  font-size: x-small;
  display: inline;
}
.player-item-MLB-true {
  border-top: 3px solid #f8e537;
  border-bottom: 3px solid #f8e537;
  width:auto;
  display: inline;
  height: 125px;
}
/* dirty CSS for when 'lock' feature is *not* checked */
.player-item-MLB-false {
  width:auto;
  display: inline;
  height: 125px;
}
.player-item {
  padding: 5px 8px;
  margin: 7px 0;
  width:auto;
  display: inline;
  height: 125px;
}
.player-itemLock {
  padding: 5px 8px;
  margin: 7px 0;
  border-top: none;
  border-bottom: none;
  border-right: none;
  width:auto;
  display: inline;
  height: 125px;
}
.player-item-PGA-col {
  padding: 5px 8px;
  margin: 7px 0;
  width:auto;
  display: inline;
}
.player-item-pga-smallfont {
  padding: 5px 8px;
  margin: 35px 0;
  width:auto;
  display: inline;
  font-size: x-small;
  text-align: right;
  height: 125px;
}
.player-item-pga-normalfont {
  padding: 5px 8px;
  margin: 35px 0;
  width:auto;
  display: inline;
  font-size: small;
  text-align: right;
  height: 125px;
}
player-item-NFL-col {
  padding: 5px 8px;
  margin: 5px 0;
  width:auto;
  display: inline;
  font-weight: bold;
  font-size: smaller;
}
.player-item-responsive {
  visibility: hidden;
}
.player-item-PGA-col-responsive {
  visibility: hidden;
}
.player-item-NFL-col-responsive {
  visibility: hidden;
}
.player-item-gamedate {
  padding: 5px 8px;
  font-size: x-small;
  margin-top: 22px;
  height: 125px;
}
.player-item-margintop {
 visibility: hidden;
}
.player-item-NFL-logo {
  visibility: hidden;
 }
.player-item-funnel-logo {
  visibility: hidden;
}
.player-item-margintop-center
{
 visibility: hidden;
}
.player-item-marginauto
{
 visibility: hidden;
}
.player-item-margintop_DK
{
 visibility: hidden;
}
.player-item-margintop_FD
{
 visibility: hidden;
}
.OppRankMLB {
  font-size:x-small;
  display:flex; /* inline */
  margin-left: 5px;
}
.horizontal-slider {
  width: 100%;
  max-width: 375px;
  height: 5vh;
  margin:auto;
}

.radio-label {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-left: 5px;
}

.radioImageDiv {
  display: flex;
  width: 120px;
  height: 100px;
}

.custom-radio {
  left: -6px;
  top: 4px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display:inline-block;
  position: relative;
}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 9px;
  height: 9px;
  background: #f1356d;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 46.5%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}
.divSmallerFont {
  font-size: x-small;
  display: inline;
  visibility: visible;
}
.div-inline {
  max-width: 600px;
  display: inline;
}

.divXSmallFinePrint {
  display: inline;
  font-size: 11px;
  font-style: italic;
}

.div-flexRight {
  max-width: 375px;
  display:flex;
  justify-content:flex-end;
}
.div-flexCenter {
  max-width: 375px;
  text-align: center;
}
.labelItalic {
  font-style: italic;
  margin-left: 2px;
  margin-top: 3px;
  font-size: x-small;
  width: 130px;
}
.labelItalicMLB {
  font-style: italic;
  margin-left: 2px;
  margin-top: 3px;
  font-size: x-small;
  width: 100px;
}
.checkbox {
  display: inline-block;
  position: relative;
  width: 20px;  
  vertical-align: middle;
  background: white left top no-repeat;
  cursor:pointer;
  margin-left: 15px;
}
.divInlineAlignRight {
  display: inline;
margin-left: 0px; /*165 px for right */
}
.divInlineResponsiveHide {
  visibility: hidden;
}
.div-flexRightGenerate {
  max-width: 375px;
  display:flex;
  justify-content:flex-end;
  margin-bottom: 15px;
}
.div-quickRank {
  display:inline-block;
  width: 45px;
  height: 34px;
vertical-align: top;
}
.div-quickRankMLB {
  display:inline-block;
  width: 70px;
  height: 34px;
vertical-align: top;
text-align: left;
}
.button {
  background-color: #f1356d;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 14px;
  font-family: Quicksand;
  border-radius: 8px;
  cursor:pointer;
  position:absolute;
  margin-right: 25px;
}
.buttonQuickRank {
  background-color: white;
  border: none;
  color: #1e90ff;
  text-align: center;
  text-decoration:underline;
  display: inline;
  font-size: 10px;
  font-family: Quicksand;
  font-weight: bold;
  cursor:pointer;
  vertical-align: top;
}
.buttonQuickRankMLB {
  background-color: white;
  border: none;
  color: #1e90ff;
  text-align: left;
  text-decoration:underline;
  display: inline;
  font-size: 10px;
  font-family: Quicksand;
  font-weight: bold;
  cursor:pointer;
  vertical-align: bottom;
}
.div-flexRightGenerate-MLB {
  max-width: 375px;
  display:flex;
  justify-content:flex-end;
  margin-bottom: 1px;
}

.Matchup {
  color: #1e90ff;
  font-size: xx-small;
  font-weight: bold;
  display: inline;
}
.Matchup-MLB-Responsive {
  color: #1e90ff;
  font-size: small;
  font-weight: bold;
  display: inline;
}

.Matchupbox {
  display: inline;
  border-bottom: white;
  border-top: #1e90ff;
  border-right: white;
  border-left: white;
  border-width: 1px;
  border-style:solid;
  padding-bottom: 2px;
}
.Matchupbox-MLB {
  display: inline;
  border-bottom: white;
  border-top: white;
  border-right: white;
  border-left: white;
  border-width: 1px;
  border-style:solid;
  padding-bottom: 2px;
}
.Matchupbox-MLB-underline {
  display: inline;
  border-bottom: white;
  border-top: #1e90ff;
  border-right: white;
  border-left: white;
  border-width: 1px;
  border-style:solid;
  padding-bottom: 2px;
}
.Rating_RB {
  width:auto;
  display: inline;
  font-size: x-small;
}
.Rating_WR {
  width:auto;
  display: inline;
  font-size: x-small;
}
.Rating_TE {
  width:auto;
  display: inline;
  font-size: x-small;
}
.Lock_RB {
  width:auto;
  display: inline;
  font-size: x-small;
}
.Lock_WR {
  width:auto;
  display: inline;
  font-size: x-small;
}
.Lock_TE {
  width:auto;
  display: inline;
  font-size: x-small;
}
.Lock_G {
  width:auto;
  display: inline;
  font-size: x-small;
}
.OppRankBrutal {
  color:#8e0000;
  font-size:xx-small;
  font-weight: bold;
  display: inline;
}
.OppRankHard {
  color: #FF1e1e;;
  font-size:xx-small;
  font-weight: bold;
  display: inline;
}
.OppRankSlightlyHard {
  color: #d4b401;;
  font-size:7px;
  font-weight: bold;
  display: inline;
}
.OppRankAverage {
    font-size:xx-small;
    font-weight: bold;
    display: inline;
  }
  .OppRankSlightlyEasy {
    color: #9dd600; /* c4d600*/
    font-size:7px;
    font-weight: bold;
    display: inline;
  }
.OppRankEasy {
  color: #23ac05;
  font-size:xx-small;
  font-weight: bold;
  display: inline;
}
.OppRankCupcake {
  color: #31f207;
  font-size:xx-small;
  font-weight: bold;
  display: inline;
}
.loadingNFL {
  color:DodgerBlue;
  font-size: 18px;
  font-family: Quicksand;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 375px;
}
}